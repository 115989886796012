#app {
  display: flex;
  margin: 0;
  height: 100%;
  width: 100%;
}

.error {
  color: var(--error);
}

.success {
  color: var(--success);
}

.error,
.success {
  margin: 20px auto;
}

.warning {
  color: var(--warning);
}

.pending {
  color: var(--warning);
}
:root {
  --border-radius: 8px;

  --gray-2: #fafafa;
  --gray-3: #f5f5f5;
  --gray-4: #f0f0f0;
  --gray-5: #d9d9d9;
  --background: var(--gray-5);
  --card-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  --footer-height: 50px;

  --success: #a0d911;
  --error: #ff4d4f;
  --warning: #faad14;
  --blue-6: #1890ff;

  --spacing-1: 2px;
  --spacing-2: 3px;
  --spacing-3: 4px;
}

.ant-collapse-content-box {
  background-color: white !important;
}

.date-cell-list {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}

.date-cell-list li {
  margin-bottom: 5px;
}
.footer {
  height: var(--footer-height);
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

#cancel-form .ant-row.ant-form-item {
  margin-bottom: 0px;
}

#edit-form .ant-row.ant-form-item {
  margin-bottom: 0px;
}
#client-assignment-table tbody tr {
  cursor: pointer;
}
#dashboard-header, #dashboard-header > ul {
  background-color: none;
}

.ant-list-items {
  max-height: 30vh;
  overflow: scroll;
}

.ant-collapse-header {
  background-color: white;
}

.ant-collapse-content-box {
  background-color: var(--gray-3);
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  align-items: center !important; /* required to override ant's default styling */
}

.ant-collapse, #dashboard .ant-collapse-icon-position-left {
  max-height: 75vh;
  overflow: scroll;
}

.pendingStatus {
  background: rgba(250, 173, 20, 0.1);
  border: 1px solid var(--warning);
  color: var(--warning);
}

.deniedStatus {
  background: rgba(255, 77, 79, 0.1);
  border: 1px solid var(--error);
  color: var(--error);
}

.approvedStatus {
  background: rgba(160, 217, 17, 0.1);
  border: 1px solid var(--success);
  color: var(--success);
}

.leaverDashboard-actionButtons {
  display: flex;
  justify-content: flex-end;
}

.leaverDashboard-actionButtons button {
  border-radius: var(--border-radius);
  margin: auto 2px;
}

.leaverDashboard-approveButton:hover {
  border: 1px solid var(--success);
  color: var(--success);
}

.filter {
  margin-bottom: 10px;
}

#search-result-table tbody tr {
  cursor: pointer;
}
#search-form-sider {
    background-color: var(--gray-1);
    padding: 0 25px;
}
.leaveForm {
  height: 100%;
  margin: auto;
  width: 70%;
}

.ant-page-header {
  padding-left: 0;
}

.login {
  margin: auto;
  width: 30%;
}

.rescheduler {
  min-height: 100%;
  margin-bottom: calc(-1 * var(--footer-height));
}

.rescheduler-loadingContent {
  display: flex;
  justify-content: center;
  margin: 5vh;
}

.rescheduler-needSubsColumnWrapper {
  display: flex;
  justify-content: space-between;
}

.rescheduler-submitButton {
  margin-top: 35px;
  width: 300px;
}

#rescheduler-filter .ant-row {
  margin-bottom: 0;
}
#staging-modal .ant-card-body {
  padding: 15px;
}

.session-info {
  margin-bottom: 0;
}
