#dashboard-header, #dashboard-header > ul {
  background-color: none;
}

.ant-list-items {
  max-height: 30vh;
  overflow: scroll;
}

.ant-collapse-header {
  background-color: white;
}

.ant-collapse-content-box {
  background-color: var(--gray-3);
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  align-items: center !important; /* required to override ant's default styling */
}

.ant-collapse, #dashboard .ant-collapse-icon-position-left {
  max-height: 75vh;
  overflow: scroll;
}
