.rescheduler {
  min-height: 100%;
  margin-bottom: calc(-1 * var(--footer-height));
}

.rescheduler-loadingContent {
  display: flex;
  justify-content: center;
  margin: 5vh;
}

.rescheduler-needSubsColumnWrapper {
  display: flex;
  justify-content: space-between;
}

.rescheduler-submitButton {
  margin-top: 35px;
  width: 300px;
}

#rescheduler-filter .ant-row {
  margin-bottom: 0;
}