:root {
  --border-radius: 8px;

  --gray-2: #fafafa;
  --gray-3: #f5f5f5;
  --gray-4: #f0f0f0;
  --gray-5: #d9d9d9;
  --background: var(--gray-5);
  --card-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  --footer-height: 50px;

  --success: #a0d911;
  --error: #ff4d4f;
  --warning: #faad14;
  --blue-6: #1890ff;

  --spacing-1: 2px;
  --spacing-2: 3px;
  --spacing-3: 4px;
}
