#app {
  display: flex;
  margin: 0;
  height: 100%;
  width: 100%;
}

.error {
  color: var(--error);
}

.success {
  color: var(--success);
}

.error,
.success {
  margin: 20px auto;
}

.warning {
  color: var(--warning);
}

.pending {
  color: var(--warning);
}