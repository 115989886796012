.pendingStatus {
  background: rgba(250, 173, 20, 0.1);
  border: 1px solid var(--warning);
  color: var(--warning);
}

.deniedStatus {
  background: rgba(255, 77, 79, 0.1);
  border: 1px solid var(--error);
  color: var(--error);
}

.approvedStatus {
  background: rgba(160, 217, 17, 0.1);
  border: 1px solid var(--success);
  color: var(--success);
}

.leaverDashboard-actionButtons {
  display: flex;
  justify-content: flex-end;
}

.leaverDashboard-actionButtons button {
  border-radius: var(--border-radius);
  margin: auto 2px;
}

.leaverDashboard-approveButton:hover {
  border: 1px solid var(--success);
  color: var(--success);
}

.filter {
  margin-bottom: 10px;
}
