.ant-collapse-content-box {
  background-color: white !important;
}

.date-cell-list {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}

.date-cell-list li {
  margin-bottom: 5px;
}