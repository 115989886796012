.button-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

#cancel-form .ant-row.ant-form-item {
  margin-bottom: 0px;
}

#edit-form .ant-row.ant-form-item {
  margin-bottom: 0px;
}